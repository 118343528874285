import styles from './style.module.scss';
import Slider from "react-slick";
import classNames from "classnames";
import {useRef, useState} from "react";
import {Modal} from "./Modal/Modal";
import {createPortal} from "react-dom";

const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

export const AdaptiveGroup = () => {
    const sliderRef = useRef();
    const [slide, setSlide] = useState(1);
    const [modalList, setModalList] = useState(null);

    const next = () => {
        sliderRef.current?.slickNext();
    }

    const prev = () => {
        sliderRef.current?.slickPrev();
    }

    const handleImageClick = (e) => {
        const image = e.target;
        const parent = image.closest('[datatype="slide"]');
        const links = Array.from(parent.querySelectorAll('img'))
            .map(el => el.src)
            .sort(link => link.endsWith(image.src) ? -1 : 0)

        setModalList(links);
    }

    return (<div className={styles.wrapper} style={{
        background: 'url(/images/gridVec.svg) center center / 100% no-repeat, linear-gradient(180deg, #242B30 0%, #2C3543 100%)'
    }}>
        {modalList && createPortal(<Modal onClose={() => setModalList(null)} list={modalList}  />, document.body)}
        <div className="container">
            <div className={styles.infoWrapper}>
                <img className={styles.banner} src="/images/adapt.png" alt="Коляска у лесенки"/>
                <div className={styles.textContent}>
                    <h4>
                        Адаптация входных групп и <span>индивидуальный подход</span> к каждому клиенту!
                    </h4>
                    <p>
                        Наша компания успешно осуществляет адаптацию объектов, зданий и входных групп для маломобильных
                        категории граждан.
                    </p>
                    <p>
                        Начиная от подъездных путей, навеса, ограждения и покрытия входной площадки в здание, до
                        установки входных дверей, пандуса или подъёмника для инвалидов.
                    </p>
                    <a href="#zakazat_zvonok" >
                        <button className={classNames(styles.cardBtn, 'greenButton')}>Связаться со мной</button>
                    </a>
                </div>
            </div>
            <div className={styles.sliderBlock}>
                <p className={styles.sliderTitle}>Фотографии Реализованных проектов</p>
                <div className={styles.sliderWrapper}>
                    <Slider afterChange={(idx) => {
                        setSlide(idx + 1)
                    }}  {...settings} ref={sliderRef}>
                        <div>
                            <div datatype="slide" className={styles.slide}>
                                <div className={styles.slide_mb}>
                                    <img onClick={handleImageClick} src="/images/slide_1.png"/>
                                </div>
                                <div className={styles.slide_col}>
                                    <div className={styles.slide_sm}>
                                        <img onClick={handleImageClick} src="/images/slide_2.png"/>
                                    </div>
                                    <div className={styles.slide_sm}>
                                        <img onClick={handleImageClick} src="/images/slide_3.png"/>
                                    </div>
                                </div>
                                <div className={classNames(styles.slide_bg, styles.slide_hidden)}>
                                    <img onClick={handleImageClick} src="/images/slide_4.png"/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div datatype="slide" className={styles.slide}>
                                <div className={styles.slide_mb}>
                                    <img onClick={handleImageClick} src="/images/slide_5.png"/>
                                </div>
                                <div className={classNames(styles.slide_bg, styles.slide_hidden)}>
                                    <img onClick={handleImageClick} src="/images/slide_8.png"/>
                                </div>
                                <div className={styles.slide_col}>
                                    <div className={styles.slide_sm}>
                                        <img onClick={handleImageClick} src="/images/slide_6.png"/>
                                    </div>
                                    <div className={styles.slide_sm}>
                                        <img onClick={handleImageClick} src="/images/slide_7.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div datatype="slide" className={styles.slide}>
                                <div className={styles.slide_col}>
                                    <div className={styles.slide_sm}>
                                        <img onClick={handleImageClick} src="/images/slide_11.png"/>
                                    </div>
                                    <div className={styles.slide_sm}>
                                        <img onClick={handleImageClick} src="/images/slide_12.png"/>
                                    </div>
                                </div>
                                <div className={styles.slide_mb}>
                                    <img onClick={handleImageClick} src="/images/slide_9.png"/>
                                </div>
                                <div className={classNames(styles.slide_bg, styles.slide_hidden)}>
                                    <img onClick={handleImageClick} src="/images/slide_10.png"/>
                                </div>
                            </div>
                        </div>
                      <div>
                        <div datatype="slide" className={styles.slide}>
                          <div className={styles.slide_col}>
                            <div className={styles.slide_sm}>
                              <img onClick={handleImageClick} src="/images/slide_13.jpg"/>
                            </div>
                            <div className={styles.slide_sm}>
                              <img onClick={handleImageClick} src="/images/slide_14.jpg"/>
                            </div>
                          </div>
                          <div className={styles.slide_mb}>
                            <img onClick={handleImageClick} src="/images/slide_15.jpg"/>
                          </div>
                          <div className={classNames(styles.slide_bg, styles.slide_hidden)}>
                            <img onClick={handleImageClick} src="/images/slide_17.jpg"/>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div datatype="slide" className={styles.slide}>
                          <div className={styles.slide_col}>
                            <div className={styles.slide_sm}>
                              <img onClick={handleImageClick} src="/images/slide_18.jpg"/>
                            </div>
                            <div className={styles.slide_sm}>
                              <img onClick={handleImageClick} src="/images/slide_19.jpg"/>
                            </div>
                          </div>
                          <div className={styles.slide_mb}>
                            <img onClick={handleImageClick} src="/images/slide_20.jpg"/>
                          </div>
                          <div className={classNames(styles.slide_bg, styles.slide_hidden)}>
                            <img onClick={handleImageClick} src="/images/slide_21.jpg"/>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div datatype="slide" className={styles.slide}>
                          <div className={styles.slide_col}>
                            <div className={styles.slide_sm}>
                              <img onClick={handleImageClick} src="/images/slide_22.jpg"/>
                            </div>
                            <div className={styles.slide_sm}>
                              <img onClick={handleImageClick} src="/images/slide_23.jpg"/>
                            </div>
                          </div>
                          <div className={styles.slide_mb}>
                            <img onClick={handleImageClick} src="/images/slide_24.jpg"/>
                          </div>
                          <div className={classNames(styles.slide_bg, styles.slide_hidden)}>
                            <img onClick={handleImageClick} src="/images/slide_25.jpg"/>
                          </div>
                        </div>
                      </div>
                    </Slider>
                    <div className={styles.slider_navigation}>
                        <img src="/images/slidePrev.svg" onClick={prev} alt=""/>
                        <span>{slide} / 3</span>
                        <img src="/images/slideNext.svg" onClick={next} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}