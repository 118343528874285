import styles from './styles.module.scss';


const events = [
    'Поддержание исправного состояния оборудования;',
    'Проведение необходимых профилактических мер;',
    'Устранение критических технических неполадок;',
    'Предотвращение аварийной ситуации.'
]

export const Service = () => {


    return (<div
        datatype="observable"
        id="service"
        className={styles.wrapper}
        style={{
            background: "url('/images/ellipseFullFill.svg') right 68px top 127px no-repeat, url('/images/ellipseFullMiddle.svg') right top 60px no-repeat, url('/images/ellipse7.svg') left top 740px no-repeat, url('/images/ellipse8.svg') right top 825px no-repeat"
        }}
    >
        <div className="container">
            <h3 className={styles.heading}>Сервисное обслуживание и ремонт </h3>
            <div className={styles.topTextWrapper}>
                <img className={styles.topBanner} src="/images/service1.png" alt="Электрик полез в щиток"/>
                <div className={styles.topText}>
                    <div className={styles.textWithBorder}>
                        Мы оказываем услуги по техническому обслуживанию подъемных платформ для инвалидов в Центральном,
                        Южном и Уральском федеральных округах.
                    </div>
                    <p>
                        Для безотказной длительной работы подъемных платформ (подъемников) для МГН необходимо
                        своевременно и квалифицированно проводить плановое (согласно карты по тех. обслуживанию) и
                        профилактическое техническое обслуживание. ТО включает в себя осмотр, диагностику оборудования,
                        ремонтные работы, а также подбор подходящих запасных частей подлежащих замене.
                    </p>
                    <p>
                        Грамотное техническое обслуживание подъемников
                        позволяет <span>продлить срок службы техники</span> и избежать
                        несчастных случаев при использовании неисправного устройства.
                    </p>
                    <p>
                        Команда мастеров нашей компании готова <span>в кратчайшие сроки выехать на объект.</span>
                    </p>
                </div>
            </div>
            <div className={styles.bottom}>
                <h5>Сервисная служба «POTRUS» </h5>
                <div className={styles.bottomTextWrapper}>
                    <div className={styles.bottomText}>
                        <p>
                            Мы выполняем полный спектр работ по тех. обслуживанию подъемных платформ (подъемников) для
                            МГН и
                            инвалидов, которые направлены на:
                        </p>
                        <ul>
                            {
                                events.map((text, idx) => <li
                                    style={{
                                        backgroundImage: 'url("/images/checkIcon.svg")'
                                    }}
                                    key={idx}
                                >{text}</li>)
                            }
                        </ul>
                        <img className={styles.bottomBanner} src="/images/toolsBox.png" alt="Ящик с инструментом"/>
                        <p>
                            Обслуживание подъемников проводится квалифицированными специалистами с большим опытом
                            работы. Для
                            быстрого и точного устранения неполадок в подъемных устройствах, наша сервисная служба
                            использует
                            современное диагностическое оборудование.
                        </p>
                        <p>
                            По завершению проекта заказчик получает гарантию на все выполненные работы.
                        </p>
                    </div>
                    <img className={styles.bottomBanner} src="/images/toolsBox.png" alt="Ящик с инструментом"/>
                </div>
            </div>
        </div>
    </div>)
}