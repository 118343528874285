import styles from './style.module.scss';
import classNames from "classnames";
import lifts from './product.json';
import {useRef, useState} from "react";
import {createPortal} from "react-dom";
import {Modal} from "./Modal/Modal";


export const OutProductions = () => {
    const [openProd, setOpenProd] = useState(null);
    const blockRef = useRef(null);

    return (<div
        ref={blockRef}
        className={styles.ellipses}
        style={{
            background: 'url(/images/smallFullEllipse.svg) right -80px top 22px no-repeat, url(/images/ellipseFill.svg) no-repeat 0px 492px,  url(/images/ellipseBold.svg) no-repeat 0px 412px, url(/images/ellipseBigBold.svg) bottom -170px right 0 no-repeat'
        }}
    >
        <div datatype="observable" id="our-production" className={classNames('container', styles.wrapper)}>
            <h3 className={styles.heading}>Наша продукция</h3>
            <div className={styles.cards}>
                {
                    lifts.map((lift, idx) => (<div className={styles.card} key={idx}>
                        <img src={lift.preview} className={styles.cardImage} alt=""/>
                        <p className={styles.cardTitle}>{lift.title}</p>
                        <div className={styles.cardList}>
                            {
                                lift.descriptions.map((text, idx) => (
                                    <p key={idx} dangerouslySetInnerHTML={{__html: text}}></p>))
                            }
                        </div>
                        <p className={styles.cardPrice}>Цена: <b>{lift.price}</b></p>
                        <button onClick={() => setOpenProd(lift)} className={classNames(styles.cardBtn, 'greenButton')}>Подробнее</button>
                    </div>))
                }
                {
                    openProd && createPortal(<Modal onClose={() => setOpenProd(null)} prod={openProd} />, document.body)
                }
            </div>
        </div>
    </div>)
}
