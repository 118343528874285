import styles from './style.module.scss';
import Slider from "react-slick";
import './style.css';
import {useEffect} from "react";

const setting = {
    customPaging: '0px',
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                arrows: false,
            }
        },
    ]
}

export const Modal = ({list, onClose}) => {

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    return (<div datatype="modal-slide" className={styles.bg}>
        <div className={styles.modal}>
            <div className={styles.close} onClick={onClose}>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M21.5 2.615L19.385 0.5L11 8.885L2.615 0.5L0.5 2.615L8.885 11L0.5 19.385L2.615 21.5L11 13.115L19.385 21.5L21.5 19.385L13.115 11L21.5 2.615Z"
                        fill="white"/>
                </svg>
            </div>
            <Slider {...setting}>
                {
                    list.map(link => <div className={styles.imageWrapper} key={link}>
                        <img className={styles.image} src={link}/>
                    </div>)
                }
            </Slider>
        </div>
    </div>)
}