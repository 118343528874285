import styles from './style.module.scss';
import classNames from "classnames";
import {useState} from "react";


const items = [
    {
        title: 'POTRUS H3',
        download: '/files/pdf/POTRUS_H3.zip',
        images: ['/images/pdfPreviews/h3_1.png', '/images/pdfPreviews/h3_2.png'],
        fileCount: 3
    },
    {
        title: 'POTRUS NH3',
        download: '/files/pdf/POTRUS_NH3.zip',
        images: ['/images/pdfPreviews/nh3_1.png'],
        fileCount: 1
    },
    {
        title: 'POTRUS CH6',
        download: '/files/pdf/POTRUS_CH6.zip',
        images: ['/images/pdfPreviews/ch6_1.png'],
        fileCount: 1
    },
    {
        title: 'POTRUS CNH6',
        download: '/files/pdf/POTRUS_CNH6.zip',
        images: ['/images/pdfPreviews/cnh6_1.png', '/images/pdfPreviews/cnh6_2.png'],
        fileCount: 2
    },
    {
        title: 'POTRUS 001, POTRUS 001A, POTRUS 001Б',
        download: '/files/pdf/POTRUS_001_POTRUS_001А_POTRUS_001Б.zip',
        images: ['/images/pdfPreviews/ptu_1.png', '/images/pdfPreviews/ptu_2.png'],
        fileCount: 3
    },
    {
        title: 'POTRUS 002',
        download: '/files/pdf/POTRUS_002.zip',
        images: ['/images/pdfPreviews/ch6_2.png'],
        fileCount: 2
    },
];

export const Blueprints = () => {
    const [activeIdx, setActiveIdx] = useState(0);


    return (<div className={classNames("container")}>
        <h3 className={styles.heading}>Чертежи подъемников potrus</h3>
        <p className={styles.text}>
            Мы представляем информацию необходимую для архитекторов, проектировщиков и конструкторов для подготовки
            проектов
            и проектно-сметной документации, в которую закладываются подъемники для инвалидов и МГН. Вы можете скачать
            файлы, которые включают в себя габаритно-установочные чертежи и строительные задания.</p>
        <p className={styles.text}>
            Для получения более подробной информации пишите нам на почту: <a
            href="mailto:Zakaz@potrus.ru">Zakaz@potrus.ru</a>
        </p>

        <div className={styles.cards}>
            {
                items.map((item, idx) => (<div className={classNames(styles.card, {[styles.open]: idx === activeIdx})} key={idx}>
                    <div className={styles.title} onClick={() => setActiveIdx(idx)}>
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.41 0.5L6 4.82659L10.59 0.5L12 1.83198L6 7.5L0 1.83198L1.41 0.5Z"
                                  fill="#F0F3F4"/>
                        </svg>
                        {item.title}
                    </div>
                    <div className={styles.content}>
                        <div className={styles.images}>
                            {
                                item.images.map(link => <img key={link} src={link} alt="PDF preview"/>)
                            }
                        </div>
                        <a download href={item.download} className={styles.downloadTitle}>
                            <svg width="18" height="21" viewBox="0 0 18 21" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.45234 11.9625L9.45937 11.9297C9.59531 11.3695 9.76641 10.6711 9.63281 10.0383C9.54375 9.53906 9.17578 9.34453 8.86172 9.33047C8.49141 9.31406 8.16094 9.525 8.07891 9.83203C7.92422 10.3945 8.0625 11.1633 8.31562 12.143C7.99687 12.9023 7.48828 14.0062 7.11563 14.6625C6.42188 15.0211 5.49141 15.5742 5.35312 16.2727C5.325 16.4016 5.35781 16.5656 5.43516 16.7133C5.52187 16.8773 5.66016 17.0039 5.82187 17.0648C5.89219 17.0906 5.97656 17.1117 6.075 17.1117C6.4875 17.1117 7.15547 16.7789 8.04609 15.2508C8.18203 15.2063 8.32266 15.1594 8.45859 15.1125C9.09609 14.8969 9.75703 14.6719 10.3547 14.5711C11.0156 14.925 11.768 15.1523 12.2789 15.1523C12.7852 15.1523 12.9844 14.8523 13.0594 14.6719C13.1906 14.3555 13.1273 13.957 12.9141 13.7438C12.6047 13.4391 11.8523 13.3594 10.6805 13.5047C10.1039 13.1531 9.72656 12.675 9.45234 11.9625ZM6.88125 15.5227C6.55547 15.9961 6.30937 16.2328 6.17578 16.3359C6.33281 16.0477 6.63984 15.743 6.88125 15.5227ZM8.93438 10.0031C9.05625 10.2117 9.03984 10.8422 8.94609 11.1609C8.83125 10.6945 8.81484 10.0336 8.88281 9.95625C8.90156 9.95859 8.91797 9.97266 8.93438 10.0031ZM8.89688 12.8273C9.14766 13.2609 9.46406 13.6336 9.81328 13.9102C9.30703 14.025 8.84531 14.2148 8.43281 14.3836C8.33437 14.4234 8.23828 14.4633 8.14453 14.5008C8.45625 13.9359 8.71641 13.2961 8.89688 12.8273ZM12.5438 14.3625C12.5461 14.3672 12.5484 14.3742 12.5344 14.3836H12.5297L12.525 14.3906C12.5063 14.4023 12.3141 14.5148 11.4867 14.1891C12.4383 14.1445 12.5414 14.3602 12.5438 14.3625ZM17.0297 5.26406L11.9859 0.220313C11.8453 0.0796875 11.6555 0 11.4562 0H1.5C1.08516 0 0.75 0.335156 0.75 0.75V20.25C0.75 20.6648 1.08516 21 1.5 21H16.5C16.9148 21 17.25 20.6648 17.25 20.25V5.79609C17.25 5.59687 17.1703 5.40469 17.0297 5.26406ZM15.5203 6.14062H11.1094V1.72969L15.5203 6.14062ZM15.5625 19.3125H2.4375V1.6875H9.51562V6.75C9.51562 7.01107 9.61934 7.26145 9.80394 7.44606C9.98855 7.63066 10.2389 7.73438 10.5 7.73438H15.5625V19.3125Z"
                                    fill="#62C624"/>
                                <path
                                    d="M9.45234 11.9625L9.45937 11.9297C9.59531 11.3695 9.76641 10.6711 9.63281 10.0383C9.54375 9.53906 9.17578 9.34453 8.86172 9.33047C8.49141 9.31406 8.16094 9.525 8.07891 9.83203C7.92422 10.3945 8.0625 11.1633 8.31562 12.143C7.99687 12.9023 7.48828 14.0062 7.11563 14.6625C6.42188 15.0211 5.49141 15.5742 5.35312 16.2727C5.325 16.4016 5.35781 16.5656 5.43516 16.7133C5.52187 16.8773 5.66016 17.0039 5.82187 17.0648C5.89219 17.0906 5.97656 17.1117 6.075 17.1117C6.4875 17.1117 7.15547 16.7789 8.04609 15.2508C8.18203 15.2063 8.32266 15.1594 8.45859 15.1125C9.09609 14.8969 9.75703 14.6719 10.3547 14.5711C11.0156 14.925 11.768 15.1523 12.2789 15.1523C12.7852 15.1523 12.9844 14.8523 13.0594 14.6719C13.1906 14.3555 13.1273 13.957 12.9141 13.7438C12.6047 13.4391 11.8523 13.3594 10.6805 13.5047C10.1039 13.1531 9.72656 12.675 9.45234 11.9625ZM6.88125 15.5227C6.55547 15.9961 6.30937 16.2328 6.17578 16.3359C6.33281 16.0477 6.63984 15.743 6.88125 15.5227ZM8.93438 10.0031C9.05625 10.2117 9.03984 10.8422 8.94609 11.1609C8.83125 10.6945 8.81484 10.0336 8.88281 9.95625C8.90156 9.95859 8.91797 9.97266 8.93438 10.0031ZM8.89688 12.8273C9.14766 13.2609 9.46406 13.6336 9.81328 13.9102C9.30703 14.025 8.84531 14.2148 8.43281 14.3836C8.33437 14.4234 8.23828 14.4633 8.14453 14.5008C8.45625 13.9359 8.71641 13.2961 8.89688 12.8273ZM12.5438 14.3625C12.5461 14.3672 12.5484 14.3742 12.5344 14.3836H12.5297L12.525 14.3906C12.5063 14.4023 12.3141 14.5148 11.4867 14.1891C12.4383 14.1445 12.5414 14.3602 12.5438 14.3625ZM17.0297 5.26406L11.9859 0.220313C11.8453 0.0796875 11.6555 0 11.4562 0H1.5C1.08516 0 0.75 0.335156 0.75 0.75V20.25C0.75 20.6648 1.08516 21 1.5 21H16.5C16.9148 21 17.25 20.6648 17.25 20.25V5.79609C17.25 5.59687 17.1703 5.40469 17.0297 5.26406ZM15.5203 6.14062H11.1094V1.72969L15.5203 6.14062ZM15.5625 19.3125H2.4375V1.6875H9.51562V6.75C9.51562 7.01107 9.61934 7.26145 9.80394 7.44606C9.98855 7.63066 10.2389 7.73438 10.5 7.73438H15.5625V19.3125Z"
                                    fill="black" fillOpacity="0.2"/>
                            </svg>
                            <span>
                                Скачать файлы ({item.fileCount})
                           </span>
                        </a>
                    </div>
                </div>))
            }
        </div>


    </div>)
}