import styles from './style.module.scss';
import {useState} from "react";
import {sendMessage} from "../../utils/sendMessage";
import classNames from "classnames";


export const Call = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isSended, setIsSended] = useState(false);

    const send = () => {
        if (!name.trim().length || !phone.trim().length) return;
        setIsSending(true);
        sendMessage(`Заказ звонка:%0A имя: ${name} %0A номер: ${phone}`)
            .then(() => {
                setName('');
                setPhone('');
                setIsSended(true)
            })
            .finally(() => setIsSending(false))
    }

    return (<div
        id="zakazat_zvonok"
        style={{background: 'url(/images/callBg.png) right no-repeat, linear-gradient(90deg, #DFE5E7 36.75%, #F6F7F9 68.68%, #F6F7F9 72.43%)'}}
        className={styles.wrapper}
    >
        <div className="container">
            {
                isSended ?
                    <div className={styles.sended}>
                        <p className={styles.sendedHeading}>Заявка отправлена!</p>
                        <p className={styles.sendedText}>Наши менеджеры свяжутся с вами в ближайшее время.</p>
                        <button onClick={() => setIsSended(false)} className={classNames(styles.sendedButton, 'greenButton')}>Хорошо</button>
                    </div> :
                    <>
                        <h3 className={styles.heading}>
                            Закажите звонок — получите <span>консультацию!</span>
                        </h3>
                        <div className={styles.forms}>
                            <input value={name} onChange={e => setName(e.target.value)} placeholder="Введите имя"
                                   type="text"/>
                            <input value={phone} onChange={e => setPhone(e.target.value)}
                                   placeholder="Введите номер телефона*" type="text"/>
                        </div>
                        <button disabled={isSending} onClick={send} className={styles.button}>заказать звонок</button>
                    </>
            }
        </div>
    </div>)
}