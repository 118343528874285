import styles from './style.module.scss'
import classNames from "classnames";
import {useState} from "react";
import {sendMessage} from "../../utils/sendMessage";


export const ScreenOne = () => {
    const [value, setValue] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isSended, setIsSended] = useState(false)

    const sendNumber = () => {
        if (!value.trim().length) return;
        setIsSending(true);
        sendMessage(`Заявка на консультацию: ${value}`)
            .then(() => {
                setValue('')
                setIsSended(true);
            })
            .finally(() => setIsSending(false))
    }

    return (<div className={classNames('container', styles.wrapper)}>
        <img className={styles.image} src="/images/reliableEquipment.png" alt=""/>
        <div className={styles.bgWrapper}>
            <div className={styles.bgTop}>
                <div className={classNames('container', styles.topContent)}>
                    <h1>Надёжное подъёмное оборудование </h1>
                    <p className={styles.description}>
                        Наша компания оказывает услуги индивидуального проектирования подъёмных устройств
                    </p>
                    <a href="#our-production" className={styles.btn}>
                        Наша продукция
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 16L6.575 14.6L12.175 9H0V7H12.175L6.575 1.4L8 0L16 8L8 16Z" fill="white"/>
                        </svg>
                    </a>
                </div>
            </div>
            <div className={styles.bgBottom}>
                <div className={classNames('container', styles.bottomContent)}>
                    {
                        isSended ?
                            <div className={styles.sended}>
                                <p className={styles.sendedHeading}>Заявка отправлена!</p>
                                <p className={styles.sendedText}>Наши менеджеры свяжутся с вами в ближайшее время.</p>
                                <button onClick={() => setIsSended(false)} className={classNames('greenButton', styles.sendedButton)}>Хорошо</button>
                            </div> :
                            <>
                                <p className={styles.bottomHeading}>Закажи консультацию прямо сейчас!</p>
                                <input value={value} onChange={e => setValue(e.target.value)} className={styles.phone}
                                       type="text"
                                       placeholder="Введите номер телефона"/>
                                <p onClick={sendNumber}
                                   className={classNames(styles.btn, 'greenButton', {[styles.disabled]: isSending})}>Перезвонить
                                    мне</p>
                            </>
                    }
                </div>
            </div>
        </div>
        <div className={classNames(styles.footer, 'container')}>
            <div className={styles.item}>
                <div className={styles.imageWrapper}>
                    <img src="/images/mapIcon.svg" alt="карта"/>
                </div>
                <div>
                    <p className={styles.title}>Адрес</p>
                    <div className={styles.text}>
                        454081, Челябинская область, г. Челябинск, ул. Попова, д.1
                    </div>
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.imageWrapper}>
                    <img src="/images/operIcon.svg" alt="карта"/>
                </div>
                <div>
                    <p className={styles.title}>Контакты</p>
                    <div className={styles.text}>
                        <a href="tel:+7 (912) 083-13-64">+7 (912) 083-13-64</a>
                        <a href="mailto:Zakaz@potrus.ru">Zakaz@potrus.ru</a>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}