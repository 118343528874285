import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Header} from "../../components/Header/Header";
import {ScreenOne} from "../../components/ScreenOne/ScreenOne";
import {AboutCompany} from "../../components/AboutCompany/AboutCompany";
import {OutProductions} from "../../components/OutProductions/OutProductions";
import {AdaptiveGroup} from "../../components/AdaptiveGroup/AdaptiveGroup";
import {Service} from "../../components/Service/Service";
import {Installation} from "../../components/Installation/Installation";
import {Blueprints} from "../../components/Blueprints/Blueprints";
import {Delivery} from "../../components/Delivery/Delivery";
import {Call} from "../../components/Call/Call";
import {Footer} from "../../components/Footer/Footer";


export const IndexPage = () => {

    return (<>
        <Header/>
        <ScreenOne />
        <AboutCompany />
        <OutProductions />
        <AdaptiveGroup />
        <Service />
        <Installation />
        <Blueprints />
        <Delivery />
        <Call />
        <Footer />
    </>)
}