import styles from './style.module.scss';
import classNames from "classnames";
import {useState} from "react";


const items = [
    {title: "Собственное производство оборудования ", icon: '/images/myProduction.svg'},
    {title: "Адаптация входных групп", icon: '/images/adaptiveGrout.svg'},
    {title: "Индивидуальный подход к каждому клиенту", icon: '/images/individualClient.svg'},
    {title: "Доставка по всей России и странам СНГ", icon: '/images/sendSNG.svg'},
]

const docs = {
    reglament: [
        {title: 'Технический регламент таможенного союза ТР ТС 004/2011', link: '/files/post/ТР_ТС_004_2011.pdf'},
        {title: 'Технический регламент таможенного союза ТР ТС 010/2011', link: '/files/post/ТР_ТС_010_2011.pdf'},
        {title: 'Технический регламент таможенного союза ТР ТС 020/2011', link: '/files/post/ТР_ТС_020_2011.pdf'},
    ],
    postanovlenie: [
        {title: 'Постановление РФ от 24 июня 2017 года №743', link: '/files/post/ПОСТАНОВЛЕНИЕ_№743_от_24_июня_2017.pdf'},
    ]
}

export const AboutCompany = () => {
    const [activeTab, setActiveTab] = useState('info')

    return (<div
        className={styles.ellipses}
        style={{
            background: 'url(/images/smallEllipse.svg) left 150px no-repeat, url(/images/bigEllipse.svg) left 57px no-repeat, url(/images/smallFullEllipse.svg) right 12px top 22px no-repeat'
        }}
    >
        <div datatype="observable" id="about-company" className={classNames('container', styles.wrapper)}>
            <div className={styles.infoWrapper}>
                <div className={styles.info}>
                    <h3 className={styles.heading}>О компании</h3>
                    <div className={styles.tabs}>
                        <p onClick={() => setActiveTab('info')}
                           className={classNames(styles.tab, {[styles.active]: 'info' === activeTab})}>Информация</p>
                        <p onClick={() => setActiveTab('docs')}
                           className={classNames(styles.tab, {[styles.active]: 'docs' === activeTab})}>Документы</p>
                    </div>
                    {
                        activeTab === 'info' ?
                            <div className={styles.aboutTextWrapper}>
                                <p>
                                    Компания «POTRUS» более 13 лет занимается разработкой новых моделей подъемников, их
                                    изготовлением, продажей и установкой во всех городах России и СНГ, а также оказывает
                                    услуги сервисного обслуживания.
                                </p>
                                <p>
                                    Одним из основных направлений компания «POTRUS» является улучшение качества жизни
                                    людей с нарушением статодинамической функции.
                                </p>
                                <p>
                                    Инновационные идеи инженеров компании позволили выйти на совершенно новый уровень в
                                    реализации программы РФ «Доступная среда».
                                </p>
                            </div> :
                            <div className={styles.aboutDocsWrapper}>
                                <p className={styles.docsGroupHeading}>Технические регламенты</p>
                                <div className={styles.dpfRowWrapper}>
                                    {
                                        docs.reglament.map(({title, link}, idx) => (<div
                                            className={styles.pdfRow}
                                            key={idx}
                                        >
                                            <img src="/images/pdfIcon.svg" alt="PDF icon"/>
                                            <a target="_blank" href={link}>{ title }</a>
                                        </div>))
                                    }
                                </div>
                                <p className={styles.docsGroupHeading}>Постановления РФ</p>
                                <div className={styles.dpfRowWrapper}>
                                    {
                                        docs.postanovlenie.map(({title, link}, idx) => (<div
                                            className={styles.pdfRow}
                                            key={idx}
                                        >
                                            <img src="/images/pdfIcon.svg" alt="PDF icon"/>
                                            <a target="_blank" href={link}>{ title }</a>
                                        </div>))
                                    }
                                </div>
                            </div>
                    }
                </div>
                <img className={styles.banner} src="/images/portusOffice.png" alt="Наш офис"/>
            </div>
            <div className={styles.items}>
                {
                    items.map(({title, icon}, idx) => (<div className={styles.item} key={idx}>
                        <img className={styles.itemIcon} src={icon} alt="Icon"/>
                        <p className={styles.itemTitle}>{title}</p>
                    </div>))
                }
            </div>
        </div>
    </div>)
}