import styles from './style.module.scss';
import classNames from "classnames";
import {useEffect, useState} from "react";

const links = [
    {title: 'О компании', href: '#about-company'},
    {title: 'Наша продукция', href: '#our-production'},
    {title: 'Сервис', href: '#service'},
    {title: 'Доставка', href: '#delivery'},
]

const trottle = (cb, count = 10) => {
    let localCount = 0;

    return () => {
        localCount--;
        if (localCount <= 0) {
            cb();
            localCount = count;
        }
    }
}

export const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [activeLink, setActiveLink] = useState('about-company');

    useEffect(() => {
        let oldScrollTop = window.document.documentElement.scrollTop;

        const handleScroll = trottle(() => {
            let scrollTop = window.document.documentElement.scrollTop;
            if (oldScrollTop < scrollTop) {
                setIsVisible(false)
            } else {
                setIsVisible(true)
            }
            oldScrollTop = scrollTop;
        })

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, []);

    useEffect(() => {
        const blocks = Array.from(document.querySelectorAll('[datatype="observable"]'));

        blocks.forEach((element) => {
            const handleIntersection = function(entries) {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setActiveLink(entry.target.id);
                }
            };
            const observer = new IntersectionObserver(handleIntersection, { threshold: 0.1 });
            observer.observe(element);
        })
    }, [])

        const handleLinkClick = (href) => {
        setTimeout(() => {setActiveLink(href.slice(1))}, 0)
        // const href =
            console.log(href)
        }

    return (<header className={classNames(styles.headerWrapper, {[styles.open]: isOpen, [styles.visible]: isVisible, [styles.unVisible]: !isVisible,})}>
        <div className={classNames('container', styles.header)}>
            <img className={styles.logo} src="/images/logo.svg" alt="Portus логотип"/>
            <div className={styles.contentWrapper}>
                <nav className={styles.navigation}>
                    {
                        links.map(({title, href}, idx) => (<a
                            onClick={() => handleLinkClick(href)}
                            key={idx}
                            href={href}
                            className={classNames({[styles.active]: `#${activeLink}` === href})}
                        >
                            {title}
                        </a>))
                    }
                </nav>

                <div className={styles.contactsWrapper}>
                    <img src="/images/callIcon.svg" alt="Контакты"/>
                    <div className={styles.phoneNumbers}>
                        <a href="tel:+7 (912) 083-13-64">+7 (912) 083-13-64</a>
                        <a href="tel:+7 (800) 100-11-20">+7 (800) 100-11-20</a>
                        <a href="mailto:zakaz@potrus.ru">zakaz@potrus.ru</a>
                    </div>
                    <div className={styles.addresWrapper}>
                        <p>Адрес</p>
                        <address>
                            454081, Челябинская область, г. Челябинск, ул. Попова, д.1
                        </address>
                    </div>
                </div>
            </div>
            <div className={styles.burgerMenu} onClick={() => setIsOpen(prevState => !prevState)}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </header>)
}