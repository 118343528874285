import styles from './style.module.scss';
import classNames from "classnames";
import {useEffect, useState} from "react";
import {sendMessage} from "../../../utils/sendMessage";


const OpenClose = () => (<>
    <svg className={styles.openTabIcon} width="24" height="25" viewBox="0 0 24 25" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.45">
            <path d="M19 13.498H13V19.498H11V13.498H5V11.498H11V5.498H13V11.498H19V13.498Z" fill="#373F45"/>
        </g>
    </svg>
    <svg className={styles.closeTabIcon} width="24" height="24" viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M19 12.998H5V10.998H19V12.998Z" fill="#F0F3F4"/>
    </svg>
</>)

const CNH6 = () => {
    return (<div className={styles.tabCommonInfo}>
        <p className={styles.title}>Описание</p>
        <p className={styles.text}>
            <b>POTRUS СNH6</b> — это надежная закрытая подъемная платформа, предназначенная для подъема (спуска) лежачих
            больных. Имеет платформу размера 950х2100 мм (или 900х2200 мм), чтобы в нее помещалась больничная каталка с пациентом.
            Платформа может быть установлена как на улице, так и в помещении с высотой подъема до 6,0 метров.<br/><br/>
            Цена подъемной платформы закрытого типа напрямую зависит от типа конструкции и от габаритных размеров.
        </p>
        <p className={styles.title}>Особенности</p>
        <ul>
            <li><b>Надёжный привод</b><br/>
                Система с винтовым приводом прочна, надежна и не требует особого обслуживания. 100% электрический, без
                гидравлики.
            </li>
            <li><b>Экономия пространства</b><br/> 70% занимаемой площади занимает пассажирское пространство.</li>
            <li><b>Простая интеграция</b><br/> Минимальная адаптация здания.</li>
          <li><b>Быстрая установка</b><br/> Ваш лифт может быть установлен всего за 2 - 3 дня.</li>
        </ul>
        <p className={styles.text}>Подъемник можно установить с углублением в полу (приямок) всего 50 мм или прямо на полу,
          если добавить пандус. Все механизмы и электрооборудование с винтовым приводом помещаются внутри лифта. Нет
            необходимости в отдельном машинном зале.</p>
    </div>)
}

const H3 = () => {
    return (<div className={styles.tabCommonInfo}>
        <p className={styles.title}>Описание</p>
        <p className={styles.text}>
            <b>POTRUS H3</b> — это надежная открытая подъемная платформа для инвалидов и других маломобильных людей,
            которая может быть
            установлена как на улице, так и в помещении с высотой подъема до 2,0 метров.
        </p>
        <p className={styles.title}>Особенности</p>
        <ul>
            <li><b>Легко установить</b><br/>
                Имеет очень простую конструкцию. Никакого приямка или шахты не требуется.
            </li>
            <li><b>Найдите нужный размер</b><br/> Доступен с размерами платформы 950×1250 и 1100×1400 мм, а также
                возможен индивидуальный размер по согласованию.
            </li>
            <li><b>Безопасно и легко использовать</b><br/> Наши подъемники отвечают требованиям ТР ТС 010/2011 и
                являются безопасными и
                простыми в использовании.
            </li>
        </ul>
    </div>)
}

const NH3 = () => {
    return (<div className={styles.tabCommonInfo}>
        <p className={styles.title}>Описание</p>
        <p className={styles.text}>
            <b>POTRUS NH3</b> — предназначена для подъема (спуска) лежачих больных, имеет платформу большего размера,
            чтобы в нее помещалась больничная каталка с
            пациентом. Платформа может быть установлена как на улице, так и в помещении с высотой подъема до 2,0 метров.
        </p>
        <p className={styles.title}>Особенности</p>
        <ul>
            <li><b>Удобство в использовании</b><br/>
                Проходная платформа дает возможность уменьшить количество движений больничной каталки или инвалидного
                кресла, за счет наличия второй калитки, это условие сокращает время пребывания на подъемнике, облегчает
                выход или выезд из него.
            </li>
            <li><b>Легко установить</b><br/> Для установки подъемника не нужен технологический приямок, что дает
                возможность экономить пространство, а также
                экономить средства, т.к. такой вариант установки, всегда имеет меньшую стоимость.
            </li>
            <li><b>Безопасно и легко использовать</b><br/> Подъемник имеет достаточно простую и надежную конструкцию,
                поэтому его легко обслуживать и
                ремонтировать в случае поломки.
            </li>
        </ul>
    </div>)
}
const CH6 = () => {
    return (<div className={styles.tabCommonInfo}>
        <p className={styles.title}>Описание</p>
        <p className={styles.text}>
            <b>POTRUS СH6</b> — это надежная закрытая подъемная платформа для инвалидов и других маломобильных людей,
            которая может быть
            установлена как на улице, так и в помещении с высотой подъема до 6,0 метров. <br/> <br/>
            Цена подъемной платформы закрытого типа напрямую зависит от типа конструкции и от габаритных размеров.
        </p>
        <p className={styles.title}>Особенности</p>
        <ul>
            <li><b>Надежный привод</b><br/>
                Система с винтовым приводом прочна, надежна и не требует особого обслуживания. 100%
                электрический, без гидравлики.
            </li>
            <li><b>Экономия пространства</b><br/>
                70% занимаемой площади занимает пассажирское пространство.
            </li>
            <li><b>Простая интеграция</b><br/>
                Минимальная адаптация здания.
            </li>
            <li><b>Быстрая установка</b><br/>
                Ваш лифт может быть установлен всего за 2-3 дня.
            </li>
            <p className={styles.text}>
                Подъемник можно установить с углублением в полу (приямок) всего 50 мм или прямо на полу, если добавить
                пандус. Все механизмы и электрооборудование с винтовым приводом помещаются внутри лифта. Нет необходимости в
                отдельном машинном зале.
            </p>
        </ul>
    </div>)
}
const PTU_1 = () => {
    return (<div className={styles.tabCommonInfo}>
        <p className={styles.title}>Описание</p>
        <p className={styles.text}>
            <b>ПТУ 001, ПТУ 001Б, ПТУ 001А</b> — универсальный, простой и безопасный подъемник, с высотой подъёма до
            2,5 м, изготавливается и соответствует ТУ 4836-
            001-0171841972-2014.
        </p>
        <p className={styles.title}>Особенности</p>
        <ul>
            <li>Низкая цена</li>
            <li>Оптимальное решение для личного использования
            </li>
            <li>Монтажные работы без привлечения узкопрофильных специалистов</li>
            <li>Имеет антивандальное исполнение</li>
            <li>Цифровой блок управления двигателем</li>
            <li>Исключено несанкционированное использование</li>
            <li>Легкая установка и пуско-наладка</li>
            <li>Не требует точных измерений места установки</li>
            <li>Простота монтажа и обслуживания</li>
            <li>Быстрый срок изготовления</li>
        </ul>
    </div>)
}

const PTU_2 = () => {
    return (<div className={styles.tabCommonInfo}>
        <p className={styles.title}>Описание</p>
        <p className={styles.text}>
            <b>POTRUS ПТУ 002 </b> — универсальный, простой и безопасный подъемник, изготавливается и соответствует ТУ
            28.22.11-001-05521241-2018.
        </p>
        <p className={styles.title}>Особенности</p>
        <ul>
            <li>Низкая цена</li>
            <li>Оптимальное решение для личного использования
            </li>
            <li>Монтажные работы без привлечения узкопрофильных специалистов</li>
            <li>Имеет антивандальное исполнение</li>
            <li>Все расходные материалы для монтажа в комплекте</li>
            <li>Возможность крепления, как к стене, так и к ступеням</li>
            <li>Существует возможность выбора между установкой радиоуправления и кнопочных постов, выбор цвета и
                материала корпуса, а также с нестандартным
                размером грузонесущего устройства.
            </li>
        </ul>
    </div>)
}


export const Modal = ({prod, onClose}) => {
    const {
        heading,
        images,
        characteristics,
        price,
        tech,
        additional,
        id
    } = prod.modal;

    const [slideIdx, setSlideIdx] = useState(0);
    const [mod, setMod] = useState('common_info')
    const [value, setValue] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isSended, setIsSended] = useState(false)


    const handleClick = () => {
        if (!value.trim().length) return;
        setIsSending(true)
        sendMessage(`Заказ звонка: ${value}%0A  Подъемник: ${heading}`)
            .then(() => {
                setValue('')
                setIsSended(true);
            })
            .finally(() => setIsSending(false))
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    const nextImage = (mob) => {
        const nextIdx = slideIdx + mob;
        if (nextIdx < 0) {
            return setSlideIdx(images.length - 1)
        }
        if (nextIdx >= (images.length)) {
            return setSlideIdx(0)
        }
        setSlideIdx(nextIdx)

    }

    return (<div className={styles.wrapper}>
        <div className={classNames(styles.modalWrapper, 'container')}>
            <div className={styles.modal}>
                <svg onClick={onClose} className={styles.closeIcon} width="36" height="36" viewBox="0 0 36 36"
                     fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                        <path
                            d="M28.5 9.615L26.385 7.5L18 15.885L9.615 7.5L7.5 9.615L15.885 18L7.5 26.385L9.615 28.5L18 20.115L26.385 28.5L28.5 26.385L20.115 18L28.5 9.615Z"
                            fill="#373F45"/>
                    </g>
                </svg>
                <h6 className={styles.heading}>{heading}</h6>
                <div className={styles.commonInfoWrapper}>
                    <div className={styles.sliderWRapper}>
                        <div className={styles.mainSlideWrapper}>
                            <img className={styles.mainSlide} src={images[slideIdx]}/>
                            <div className={styles.navigation}>
                                <svg onClick={() => nextImage(-1)} width="10" height="18" viewBox="0 0 10 18"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.49257 17.5L0 9L8.49257 0.5L10 2.00875L3.01486 9L10 15.9913L8.49257 17.5Z"
                                        fill="white"/>
                                </svg>
                                <span>
                                    {slideIdx + 1} / {images.length}
                                </span>
                                <svg onClick={() => nextImage(1)} width="10" height="18" viewBox="0 0 10 18" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.50743 17.5L0 15.9913L6.98514 9L0 2.00875L1.50743 0.5L10 9L1.50743 17.5Z"
                                          fill="white"/>
                                </svg>
                            </div>
                        </div>
                        <div className={styles.slideList}>
                            {
                                images.map((link, idx) => <img key={idx} onClick={() => setSlideIdx(idx)}
                                                               className={classNames({[styles.hidden]: slideIdx === idx})}
                                                               src={link}/>)
                            }
                        </div>
                    </div>
                    <div>
                        <div className={styles.mainProp}>
                            <p className={styles.title}>Основные характеристики</p>
                            <ul>
                                {
                                    characteristics.map((text, idx) => <li key={idx}>{text}</li>)
                                }
                            </ul>
                            <div className={styles.line}></div>
                            <div className={styles.price}>
                                <p>Цена</p>
                                <span>{price}</span>
                            </div>
                            <p className={styles.note}>
                                Цена подъемной платформы закрытого типа напрямую зависит от типа конструкции и от
                                габаритных размеров.
                            </p>
                        </div>
                        <div className={styles.req}>
                            {
                                isSended ?
                                    <div className={styles.sended}>
                                        <p className={styles.sendedHeading}>Заявка отправлена!</p>
                                        <p className={styles.sendedText}>Наши менеджеры свяжутся с вами в ближайшее
                                            время.</p>
                                        <div className={styles.sendedLine}></div>
                                        <p className={styles.sendedText}>Номер для обратной связи </p>
                                        <a className={styles.sendedLink} href="tel:+7 (912) 083-13-64">+7 (912)
                                            083-13-64</a>
                                    </div> :
                                    <>
                                        <b>Оставьте заявку для консультации</b>
                                        <p>Мы вам перезвоним или отправим информацию на почту.</p>
                                        <input value={value} onChange={e => setValue(e.target.value)}
                                               placeholder="Введите номер телефона" type="text"/>
                                        <button className="greenButton" disabled={isSending}
                                                onClick={handleClick}>Отправить
                                        </button>
                                    </>
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.tabsWrapper}>
                    <p onClick={() => setMod('common_info')}
                       className={classNames(styles.tab, {[styles.active]: mod === 'common_info'})}>
                        Общая информация
                        <OpenClose/>
                    </p>
                    <div className={styles.contentWrapper}>
                        {id === 'H3' && <H3/>}
                        {id === 'NH3' && <NH3/>}
                        {id === 'CH6' && <CH6/>}
                        {id === 'CNH6' && <CNH6/>}
                        {id === 'PTU_1' && <PTU_1/>}
                        {id === 'PTU_2' && <PTU_2/>}
                    </div>
                    <p
                        onClick={() => setMod('teh')}
                        className={classNames(styles.tab, {[styles.active]: mod === 'teh'})}>Технические
                        характеристики
                        <OpenClose/>
                    </p>
                    <div className={styles.contentWrapper}>
                        <div className={styles.table}>
                            {
                                tech.map(([field, value], idx) => (<div className={styles.row} key={idx}>
                                    <b>{field}</b>
                                    <p>{value}</p>
                                </div>))
                            }
                        </div>
                    </div>
                    <p
                        onClick={() => setMod('options')}
                        className={classNames(styles.tab, {[styles.active]: mod === 'options'})}>Дополнительные
                        опиции
                        <OpenClose/>
                    </p>
                    <div className={styles.contentWrapper}>
                        <p className={styles.preTableTitle}>Индивидуальный подход к каждому клиенту, гибкая ценовая
                            политика!</p>
                        <div className={styles.table}>
                            {
                                additional.map(([field, value], idx) => (<div className={styles.row} key={idx}>
                                    <b>{field}</b>
                                    <p>{value}</p>
                                </div>))
                            }
                        </div>
                        <p className={styles.afterTable}><sup>* </sup>
                          {id === 'CH6' && 'Рассчитывается менеджером'}
                          {id === 'CNH6' && 'Рассчитывается менеджером'}
                          {id === 'H3' && 'Окончательная цена после согласования комплектации с Покупателем'}
                          {id === 'NH3' && 'Окончательная цена после согласования комплектации с Покупателем'}
                          {id === 'PTU_1' && 'Окончательная цена после согласования комплектации с Покупателем'}
                          {id === 'PTU_2' && 'Цена ПТУ 002 более 3 метров зависит от длины лестничного марша'}
                          </p>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}