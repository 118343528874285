import styles from './style.module.scss'


const items = [
    {
        image: '/images/delivery_1.svg',
        title: 'Самовывоз со склада',
        description: 'Вы можете забрать продукцию с нашего склада по адресу: г. Челябинск, ул. Попова,1.'
    },
    {
        image: '/images/delivery_2.svg',
        title: 'Доставка до терминала транспортной компании',
        description: 'Привезём подъемное оборудование до терминала транспортной компании на ваш выбор.'
    },
    {
        image: '/images/delivery_3.svg',
        title: 'Доставка до указанного адреса',
        description: 'Организуем доставку до вашего склада или места установки подъемников,'
    }
]

export const Delivery = () => {


    return (<div datatype="observable" id="delivery" className="container">
        <h3 className={styles.heading}>Доставка</h3>
        <p className={styles.text}>
            Заказанные у нас подъемники для инвалидов, мы можем доставить в любой регион России, Белоруссию, Казахстан и
            другие страны СНГ удобным для вас способом.
        </p>
        <div className={styles.cards}>
            {
                items.map((item, idx) => (<div className={styles.card} key={idx}>
                    <img src={item.image} alt=""/>
                    <b>{ item.title }</b>
                    <p>{ item.description }</p>
                </div>))
            }
        </div>
    </div>)
}