import styles from './style.module.scss'
import classNames from "classnames";
import {useState} from "react";


const tabs = [
    {title: '«Под ключ» ', mod: 'key'},
    {title: '«Своими силами»', mod: 'sam'},
    {title: '«Шеф-монтаж»', mod: 'sheff'}
]

export const Installation = () => {
    const [mod, setMod] = useState(tabs[0].mod)

    const handleChangeMod = (mod) => {
        const tabEl = document.querySelector(`[datatype=${mod}]`);
        setTimeout(() => {
            tabEl.scrollIntoView({ behavior: 'smooth', block: 'nearest'})
        }, 0)
        setMod(mod);
    }

    return (<div className={classNames("container", styles.wrapper)}>
        <h3 className={styles.heading}>Монтаж</h3>
        <div className={styles.texts}>
            <p>
                Наряду с решением задачи по приобретению подъемника для инвалидов перед вами встанет очередной вопрос —
                установка этого оборудования.
            </p>
            <p>
                Данный вопрос имеет высокую значимость, так как монтаж и пуско-наладочные работы — это финальный этап
                перед
                сдачей/приемкой объекта. От качества этих работ будет зависеть работоспособность всех механизмов и
                элементов
                подъемной платформы.
            </p>
        </div>
        <div className={styles.tabs}>
            {
                tabs.map(({title, mod: localMod}, idx) => <div
                    key={idx}
                    className={classNames({[styles.active]: localMod === mod})}
                    onClick={() => handleChangeMod(localMod)}
                >
                    <p datatype={localMod}>{title}</p>
                    {
                        localMod === mod ?
                            <svg width="14" height="3" viewBox="0 0 14 3" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 2.99805H0V0.998047H14V2.99805Z" fill="#F0F3F4"/>
                            </svg> :
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.45">
                                    <path d="M19 12.998H13V18.998H11V12.998H5V10.998H11V4.99805H13V10.998H19V12.998Z"
                                          fill="#373F45"/>
                                </g>
                            </svg>
                    }

                    <div className={styles.tabContentWrapper}>
                        {
                            localMod === 'key' && <>
                                <div className={styles.tabContent}>
                                    <p>
                                        Полный комплекс услуг от нашей компании. Включающий монтаж, пусконаладочные
                                        работы,
                                        обкатку
                                        с
                                        последующей выдачей акта ПТО.
                                    </p>
                                    <p>
                                        Наша организация гарантирует качество монтажа в соответствии с требованиями
                                        Постановления
                                        Правительства
                                        №743 от 24.06.2017г. Компания «POTRUS» является «Специализированной
                                        монтажной
                                        организацией».
                                    </p>
                                    <span>
                            При данном виде монтажа гарантийный срок увеличивается до 24 месяцев.
                        </span>
                                </div>
                                <img src="/images/instalation_tab1.png" alt="Картинка"/>
                            </>
                        }
                        {
                            localMod === 'sam' && <>
                                <div className={classNames(styles.tabContent, {[styles.open]: localMod === 'sam'})}>
                                    <p>
                                        Такой вид монтажа возможен при индивидуальном (частном) использования
                                        подъемника, не
                                        требующего постановки на учет в Ростехнадзоре.
                                    </p>
                                    <p>
                                        Монтаж наших моделей подъемников, оборудованных специальными системами
                                        крепления,
                                        представляет собой простой и доступный процесс. Который детально описан
                                        в <span>технической
                                документации</span> на подъемник.
                                    </p>
                                    <p>
                                        Правильная установка подъёмников в дальнейшем уменьшает стоимость
                                        ремонта
                                        оборудования.
                                    </p>
                                </div>
                                <img src="/images/instalation_tab2.png" alt="Картинка"/>
                            </>
                        }
                        {
                            localMod === "sheff" && <>
                                <div className={styles.tabContent}>
                                    <p>
                                        <span>Дистанционная консультация</span> «ваших» квалифицированных
                                        монтажников с предоставлением им
                                        подробной инструкции по монтажу подъемников.
                                    </p>
                                    <p>
                                        Квалифицированная монтажная организация, взявшая ответственность по
                                        монтажу подъемной
                                        платформы на себя, по окончанию работ, должна:
                                    </p>
                                    <ul>
                                        <li>Внести свои сведения в паспорт подъемника</li>
                                        <li>Произвести пусконаладочные работы</li>
                                        <li>Произвести проверку, испытания и обкатку подъемника</li>
                                        <li>Произвести полное техническое освидетельствование с отметкой в
                                            паспорте
                                        </li>
                                        <li>Оформить акт приема-передачи заказчику</li>
                                    </ul>
                                    <p>
                                        Правильная установка подъёмников в дальнейшем уменьшает стоимость
                                        ремонта оборудования.
                                    </p>
                                </div>
                                <img src="/images/instalation_tab3.png" alt="Картинка"/>
                            </>
                        }
                    </div>
                </div>)
            }
        </div>
    </div>)
}